import React, { Fragment } from 'react';
import SEO from '../components/seo';
import { ThemeProvider } from 'styled-components';
import { ResetCSS } from 'common/src/assets/css/style';
import { GlobalStyle, ContentWrapper } from '../containers/Portfolio/portfolio.style';
import Sticky from 'react-stickynode';
import { DrawerProvider } from 'common/src/contexts/DrawerContext';
import { portfolioTheme } from 'common/src/theme/portfolio';
import Navbar from '../containers/Portfolio/Navbar';
import Calendar from '../containers/LetsTalk/Calendar';

const LetsTalk = () => {
  return (
    <ThemeProvider theme={portfolioTheme}>
      <Fragment>
        <SEO title="Let's talk | Mario Markovic" />
        <ResetCSS />
        <GlobalStyle />

        <ContentWrapper>
          <Sticky top={0} innerZ={9999} activeClass="sticky-nav-active">
            <DrawerProvider>
              <Navbar className="nav-static nav-centered" />
            </DrawerProvider>
          </Sticky>
          <Calendar />
        </ContentWrapper>
      </Fragment>
    </ThemeProvider>
  );
};

export default LetsTalk;
