import React from 'react'
import { Link } from 'gatsby'
import { MainWrapper, CalendarWrapper, BackToHomeWrapper } from './calendar.style'
import { ActiveStatus } from '../../Portfolio/Contact/contact.style';
import Image from 'reusecore/src/elements/Image';
import Author from 'common/src/assets/image/mario-markovic-avatar.jpg';
import Container from 'common/src/components/UI/Container';

const Calendar = () => {
    return (
        <MainWrapper>
          <Container noGutter mobileGutter width="1200px">
            <ActiveStatus className="avatar-big">
              <Image src={Author} alt="Mario Markovic Avatar" />
            </ActiveStatus>
            <h1>Let’s talk about your new project!</h1>
            <p>
              I’m here to help you start your project, website or application. <br />
              Let’s schedule a call to discuss additional information necessary to start. <br />
              Just select any time that works for you.
            </p>
            <CalendarWrapper>
              <iframe 
                src="https://calendly.com/mariomarkovic/30min?embed_domain=www.mariomarkovic.com&amp;embed_type=Inline&amp;primary_color=3444f1" 
                frameBorder="0" 
                title="Calendly" 
              />
            </CalendarWrapper>
            <BackToHomeWrapper>
              <Link to="/">Go back to Home</Link>
            </BackToHomeWrapper>
          </Container>
        </MainWrapper>
    )
}

export default Calendar